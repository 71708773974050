<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- [S]결재관련 버튼 -->
                <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
                <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
                <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
                <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
                <c-appr-btn 
                  ref="appr-btn"
                  name="work-permit-appr-btn"
                  :editable="editable"
                  :approvalInfo="approvalInfo"
                  @beforeApprAction="approvalPreWorkCheck"
                  @callbackApprAction="approvalPreWorkCheckCallback"
                  @requestAfterAction="getDetail('save')"
                />
                <c-btn
                  v-show="editable && !disabled" 
                  :isSubmit="isSave"
                  :url="saveUrl"
                  :param="preWorkCheck"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="savePreWorkCheck"
                  @btnCallback="savePreWorkCheckCallback" />
                <c-btn
                  v-show="editable && !disabled && isOld"
                  label="삭제"
                  icon="remove"
                  @btnClicked="removePreWorkCheck" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                <c-text
                  :required="true"
                  :disabled="disabled"
                  :editable="editable"
                  label="점검명"
                  name="checkName"
                  v-model="preWorkCheck.checkName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                <c-multi-select
                  :disabled="disabled"
                  :editable="editable"
                  :comboItems="checkClassItems"
                  itemText="checkClassName"
                  itemValue="sopPreWorkCheckClassId"
                  label="점검구분대상"
                  name="targets"
                  v-model="targets"
                  @datachange="changeTarget">
                </c-multi-select>
              </div>
              <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 ">
                <c-field
                  :required="true"
                  :disabled="disabled"
                  :editable="editable"
                  :data="preWorkCheck"
                  :plantCd="preWorkCheck.plantCd"
                  deptValue="deptCd"
                  type="dept_user"
                  label="관리감독자"
                  name="managerId"
                  v-model="preWorkCheck.managerId" />
              </div>
              <div class="col-xs-12 col-sm-8 col-md-4 col-lg-4">
                <c-multi-field
                  :required="true"
                  :disabled="disabled"
                  :editable="editable"
                  :data="preWorkCheck"
                  :isArray="false"
                  deptValue="deptCd"
                  type="dept_user"
                  label="근로자"
                  name="workerIds"
                  v-model="preWorkCheck.workerIds" />
              </div>
              <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 ">
                <c-datepicker
                  :required="true"
                  :range="true"
                  :disabled="disabled"
                  :editable="editable"
                  label="점검기간"
                  name="checkPeriod"
                  v-model="preWorkCheck.checkPeriod"
                />
              </div>
              <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 ">
                <c-plant
                  :required="true"
                  :disabled="disabled"
                  :editable="editable"
                  label="사업장"
                  name="plantCd"
                  v-model="preWorkCheck.plantCd">
                </c-plant>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-tab
      class="q-mt-sm"
      type="tabcard"
      align="left"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          ref="swp-tab"
          :is="tab.component"
          :key="tab.componentKey"
          :popupParam.sync="popupParam"
          :target.sync="tab.target"
          :isOld="isOld"
          :disabled="disabled"
          :height="tabHeight"
        />
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'preWorkCheckDetail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          sopPreWorkCheckId: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      preWorkCheck: {
        sopPreWorkCheckId: '',  // 작업 전 안전점검 일련번호
        plantCd: '',  // 사업장 코드
        pwcPreWorkCheckStepCd: '',  // 작업 전 안전점검 진행상태
        checkName: '',  // 점검명
        managerId: '',  // 관리감독자 일련번호
        workerIds: '',  // 근로자 일련번호(복수)
        startDate: '',  // 점검시작일
        endDate: '',  // 점검종료일
        checkPeriod: [], // 점검기간
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        targets: [],
        deleteTargets: [],
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
      },
      targets: [],
      checkClassItems: [],
      checkClassOrignItems: [],
      checkItems: [],
      tab: 'PWC0000022',
      tabItems: [],
      editable: true,
      detailUrl: '',
      listItemUrl: '',
      listClassUrl: '',
      saveUrl: '',
      deleteUrl: '',
      approvalUrl: '',
      isSave: false,
      isApproval: false,
      mappingType: 'POST',
      popupOptions: {
        isFull: true,
        isApproval: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return this.contentHeight - 300 > 300 ? String(this.contentHeight - 300) + 'px' : '300px';
    },
    isOld() {
      return Boolean(this.popupParam.sopPreWorkCheckId)
    },
    disabled() {
      // 모든 입력폼이 비활성화 되는 상황
      // - 취소  - 작업완료
      // - 결재중
      return this.isOld 
            // - 취소  - 작업완료
        && (this.preWorkCheck.pwcPreWorkCheckStepCd === 'PWC000005' 
          // 결재중인 경우 비활성화
          || this.preWorkCheck.approvalStatusCd === 'ASC0000001')
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.preWorkCheck.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.preWorkCheck.approvalStatusCd, 
        apprEditable: this.isOld 
          && this.preWorkCheck.pwcPreWorkCheckStepCd === 'PWC000001', // 결재버튼 활성화 기준
        param: this.preWorkCheck, // 결재 param
        approvalUrl: this.approvalUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000017', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          sopPreWorkCheckId: this.preWorkCheck.sopPreWorkCheckId,
          isApprContent: true
        },
        approvalRequestName: this.preWorkCheck.checkName, // 결재요청명 (문서 title)
        approvalConnId: this.preWorkCheck.sopPreWorkCheckId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    // [E] 결재관련 버튼 컨트롤
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.pwc.check.get.url
      this.listItemUrl = selectConfig.sop.pwc.item.list.url
      this.listClassUrl = selectConfig.sop.pwc.class.list.url
      this.saveUrl = transactionConfig.sop.pwc.check.update.url
      this.deleteUrl = transactionConfig.sop.pwc.check.delete.url
      this.approvalUrl = transactionConfig.sop.pwc.check.update.url
      // code setting
      // list setting
      this.getCheckClass();
      this.getDetail();
    },
    getDetail() {
      if (!this.tabItems) {
        this.tabItems = [];
      }
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopPreWorkCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.preWorkCheck, _result.data);

          this.preWorkCheck.checkPeriod = this.preWorkCheck.startDate && this.preWorkCheck.endDate ?
            [this.preWorkCheck.startDate, this.preWorkCheck.endDate] : []

          if (this.preWorkCheck.targets && this.preWorkCheck.targets.length > 0) {
            this.targets = this.$_.map(this.$_.reject(this.preWorkCheck.targets, { sopPreWorkCheckClassId: 'PWC0000022' }), 'sopPreWorkCheckClassId')
          }

          this.getCheckItems();
          this.setTabItems();

          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        // 공통 점검항목 적용
        let promises = [
          {
            func: this.getCheckClass,
          },
          {
            func: this.getCheckItems,
          },
          {
            func: this.setCommonItems,
          },
        ];
        this.$comm.orderedPromise(promises);
      }
    },
    getCheckItems() {
      // 사업장별 점검항목 정보 조회
      return new Promise(resolve => {
        this.$http.url = this.listItemUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.$store.getters.user.plantCd,
          useFlag: 'Y'
        };
        this.$http.request((_result) => {
          this.checkItems = this.$_.map(_result.data, item => {
            return this.$_.extend(item, {
              pwcWorkerResultCd: 'PR00000001',
              pwcManagerResultCd: 'PR00000001',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            })
          })
          resolve(true);
        },);
      });
    },
    getCheckClass() {
      // 점검구분 정보 조회
      return new Promise(resolve => {
        this.$http.url = this.listClassUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          useFlag: 'Y'
        };
        this.$http.request((_result) => {
          this.checkClassOrignItems = this.$_.cloneDeep(_result.data)
          this.checkClassItems = this.$_.reject(_result.data, { sopPreWorkCheckClassId: 'PWC0000022' })
          resolve(true);
        },);
      });
    },
    setCommonItems() {
      return new Promise(resolve => {
        let classData = this.$_.find(this.checkClassOrignItems, { sopPreWorkCheckClassId: 'PWC0000022' });
        this.preWorkCheck.targets.push({
          sopPreWorkCheckTargetId: '',  // 작업 전 안전점검 대상 일련번호
          sopPreWorkCheckId: '',  // 작업 전 안전점검 일련번호
          sopPreWorkCheckClassCd: classData.sopPreWorkCheckClassCd,
          sopPreWorkCheckClassName: classData.sopPreWorkCheckClassName,
          checkClassName: classData.checkClassName,
          sopPreWorkCheckClassId: 'PWC0000022',  // 작업 전 안전점검구분 일련번호
          targetIds: '',  // 대상 일련번호(복수)
          regUserId: this.$store.getters.user.userId,  // 등록자 ID
          editFlag: 'C',
          results: this.$_.cloneDeep(this.$_.filter(this.checkItems, { sopPreWorkCheckClassId: 'PWC0000022' })),
          deleteResults: [],
        })
        this.setTabItems();
        resolve(true);
      });
    },
    setTabItems() {
      if (this.preWorkCheck.targets && this.preWorkCheck.targets.length > 0) {
        this.$_.forEach(this.preWorkCheck.targets, target => {
          let checkIdx = this.$_.findIndex(this.tabItems, { name: target.sopPreWorkCheckClassId })
          if (checkIdx === -1) {
            let pushData = {}
            switch (target.sopPreWorkCheckClassCd) {
              // 작업별
              case 'PWCC000001':
                pushData = {
                  componentKey: uid(),
                  name: target.sopPreWorkCheckClassId, class: 'tab-bluegrey', icon: 'construction', label: `${target.sopPreWorkCheckClassName} - ${target.checkClassName}`, 
                  component: () => import(`${'./preWorkCheckTargetResult.vue'}`),
                  target: target
                }
                break;
              // 설비별
              case 'PWCC000005':
                pushData = {
                  componentKey: uid(),                  
                  name: target.sopPreWorkCheckClassId, class: 'tab-bluegrey', icon: 'precision_manufacturing', label: `${target.sopPreWorkCheckClassName} - ${target.checkClassName}`, 
                  component: () => import(`${'./preWorkCheckTargetResult.vue'}`),
                  target: target
                }
                break;
              // 계절별
              case 'PWCC000010':
                pushData = {
                  componentKey: uid(),                  
                  name: target.sopPreWorkCheckClassId, class: 'tab-bluegrey', icon: 'cloud', label: `${target.sopPreWorkCheckClassName} - ${target.checkClassName}`, 
                  component: () => import(`${'./preWorkCheckTargetResult.vue'}`),
                  target: target
                }
                break;
              // 기타
              case 'PWCC000015':
                // 맨 앞에 적용되도록 셋팅되어짐
                pushData = {
                  componentKey: uid(),                  
                  name: target.sopPreWorkCheckClassId, class: 'tab-lightblue', icon: 'done', label: target.checkClassName, 
                  component: () => import(`${'./preWorkCheckTargetResult.vue'}`),
                  target: target
                }
                break;
              default:
              // code block
            }
            if (this.checkTab(target.sopPreWorkCheckClassId)) {
              if (pushData.name === 'PWC0000022') {
                this.tabItems.splice(0, 0, pushData)
              } else {
                this.tabItems.push(pushData)
              }
            }
          } else {
            this.$set(this.tabItems[checkIdx], 'target', target)
          }
        })
        let deleteItems = [];
        this.$_.forEach(this.tabItems, item => {
          if (this.$_.findIndex(this.preWorkCheck.targets, { sopPreWorkCheckClassId: item.name }) === -1 && item.name !== 'PWC0000022') {
            deleteItems.push(item)
          }
        })
        if (deleteItems && deleteItems.length > 0) {
          this.$_.forEach(deleteItems, delItem => {
            this.tabItems = this.$_.reject(this.tabItems, delItem);
          });
        }
      } else {
        /**
         * 공통을 제외한 나머지 삭제
         */
        // this.tabItems = this.$_.filter(this.tabItems, (item) => {
        //   return item.name === 'PWC0000022'
        // })
        // this.$_.forEach(deleteItems, item => {
        //   this.$set(this.tabItems, item, null)
        // });
      }

      // if (this.tabItems && this.tabItems.length > 0) {
      //   let deleteItems = [];
      //   this.$_.forEach(this.tabItems, item => {
      //     if (this.$_.findIndex(this.preWorkCheck.targets, { sopPreWorkCheckClassId: item.sopPreWorkCheckClassId }) === -1) {
      //       deleteItems.push(item)
      //     }
      //   });

      //   this.$_.forEach(deleteItems, item => {
      //     this.$set(this.tabItems, item, null)
      //   });
      // }
    },
    checkTab(tab) {
      return this.$_.findIndex(this.tabItems, { name: tab }) === -1
    },
    /* eslint-disable no-unused-vars */
    changeTarget(value) {
      // ## 추가된 대상을 넣는 과정
      if (this.targets && this.targets.length > 0) {
        this.$_.forEach(this.targets, classItem => {
          if (this.$_.findIndex(this.preWorkCheck.targets, { sopPreWorkCheckClassId: classItem }) === -1) {
            let classData = this.$_.find(this.checkClassItems, { sopPreWorkCheckClassId: classItem });
            this.preWorkCheck.targets.push({
              sopPreWorkCheckTargetId: uid(),  // 작업 전 안전점검 대상 일련번호
              sopPreWorkCheckId: this.popupParam.sopPreWorkCheckId ? this.popupParam.sopPreWorkCheckId : uid(),  // 작업 전 안전점검 일련번호
              sopPreWorkCheckClassCd: classData.sopPreWorkCheckClassCd,
              sopPreWorkCheckClassName: classData.sopPreWorkCheckClassName,
              checkClassName: classData.checkClassName,
              sopPreWorkCheckClassId: classItem,  // 작업 전 안전점검구분 일련번호
              targetIds: '',  // 대상 일련번호(복수)
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
              results: this.$_.cloneDeep(this.$_.filter(this.checkItems, { sopPreWorkCheckClassId: classItem })),
              deleteResults: [],
            })
          }
        })
      }

      // ## 삭제된 대상을 빼는 과정
      if (this.preWorkCheck.targets && this.preWorkCheck.targets.length > 0) {
        let deleteItems = [];
        this.$_.forEach(this.preWorkCheck.targets, item => {
          if (this.$_.indexOf(this.targets, item.sopPreWorkCheckClassId) === -1 && item.sopPreWorkCheckClassId !== 'PWC0000022') {
            deleteItems.push(item)
          }
        })

        if (!this.preWorkCheck.deleteTargets) this.preWorkCheck.deleteTargets = [];
        this.$_.forEach(deleteItems, deleteItem => {
          if (this.$_.findIndex(this.preWorkCheck.deleteTargets, { sopPreWorkCheckClassId: deleteItem.sopPreWorkCheckClassId }) === -1
            && deleteItem.editFlag !== 'C') {
            this.preWorkCheck.deleteTargets.push(deleteItem)
          }
          let idx = this.$_.findIndex(this.preWorkCheck.targets, { sopPreWorkCheckClassId: deleteItem.sopPreWorkCheckClassId });
          this.preWorkCheck.targets.splice(idx, 1);
        })
      }
      this.setTabItems();
    },
    savePreWorkCheck() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.pwc.check.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.pwc.check.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.preWorkCheck.regUserId = this.$store.getters.user.userId
              this.preWorkCheck.chgUserId = this.$store.getters.user.userId

              this.preWorkCheck.startDate = this.preWorkCheck.checkPeriod[0]
              this.preWorkCheck.endDate = this.preWorkCheck.checkPeriod[1]
              
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    savePreWorkCheckCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.sopPreWorkCheckId = result.data
      this.getDetail();
    },
    removePreWorkCheck() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopPreWorkCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    approvalPreWorkCheck() {
      // 유효성 체크 : 1. 대상이 하나라도 입력되었는지?
      let isIng = true;
      if (!this.preWorkCheck.targets || this.preWorkCheck.targets.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '점검대상을 하나 이상 입력하세요.',
          type: 'warning', // success / info / warning / error
        });
        isIng = false;
      } 

      if (isIng) {
        this.$refs['editForm'].validate().then(_result => {
          if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '결재요청 하기 전 입력된 값을 저장합니다. 진행하시겠습니까?',
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.preWorkCheck.regUserId = this.$store.getters.user.userId
                this.preWorkCheck.chgUserId = this.$store.getters.user.userId

                this.preWorkCheck.startDate = this.preWorkCheck.checkPeriod[0]
                this.preWorkCheck.endDate = this.preWorkCheck.checkPeriod[1]
                
                this.isApproval = !this.isApproval
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      }
    },
    // [S] 결재관련 
    approvalPreWorkCheckCallback(result) { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    // [E]] 결재관련 
  }
};
</script>